// react
import React from "react";
// componenetes
import Breadcrumb from "../../components_helpers/breadcrumb";
import LoadingPage from "../../components_helpers/loading";
import TryAgain from "../../components_helpers/tryAgain";
import moment from 'moment'
// suporte
import {
  // formatDate,
  formatarValorEmDinheiro,
  formatName,
} from "../../helpers/FormatData";

const OfflineCpf = (data) => {
  // vars
  let objNew = data?.data?.newResult ? data?.data?.newResult : {};

  if (objNew && !objNew[0]?.Beneficiario?.Nome) {
    return <TryAgain />
  }

  if (!objNew) {
    return <LoadingPage />;
  }

  // funcs
  const handlePrintToPDF = () => {
    const element0 = document.querySelector("header");
    const element1 = document.querySelector(".pagetitle");
    const elementDividerPrint = document.querySelector("#dividerPrint");

    element0.style.setProperty("display", "none", "important");
    element1.style.setProperty("display", "none", "important");
    elementDividerPrint.style.setProperty("display", "block", "important");

    // Chamar a função de impressão
    window.print();

    element0.style.removeProperty("display");
    element1.style.removeProperty("display");
    elementDividerPrint.style.setProperty("display", "none", "important");
  };

  const infoRmc = (e) => {
    return (
      <>
        <tr style={{ textAlign: "center" }}>
          <td>{e?.Banco} - {" "} {e?.NomeBanco}</td>
          <td>{e?.Valor}</td>
          <td>{formatarValorEmDinheiro(e?.ValorParcela)}</td>
          <td>{e?.Contrato}</td>
          <td>{formatarValorEmDinheiro(e?.Valor_emprestimo)}</td>
          <td>{moment(e?.Data_inclusao).format('DD/MM/YYYY')}</td>
        </tr>
      </>
    );
  }

  const infoRcc = (e) => {
    return (
      <>
        <tr style={{ textAlign: "center" }}>
          <td>
            {e?.Banco} -{" "}
            {e?.NomeBanco}
          </td>
          <td>{formatarValorEmDinheiro(e?.Valor)}</td>
          <td>{e?.ValorParcela}</td>
          <td>{e?.Contrato}</td>
          <td>{formatarValorEmDinheiro(e?.Valor_emprestimo)}</td>
          <td>{moment(e?.Data_inclusao).format('DD/MM/YYYY')}</td>
        </tr>
      </>
    );
  }

  const infoEmprestimos = (e) => {
    return (
      <>
        <tr style={{ textAlign: "center" }}>
          <td>
            {e?.Banco} - {e?.NomeBanco}
          </td>
          <td>{formatarValorEmDinheiro(e?.ValorParcela)}</td>
          <td>{formatarValorEmDinheiro(e?.Quitacao)}</td>
          <td>{e?.Prazo}</td>
          <td>{e?.Tipo}</td>
          <td>{formatarValorEmDinheiro(e?.Taxa)}</td>
          <td>{e?.ParcelasRestantes}</td>
          <td>{e?.Contrato}</td>
          <td>{formatarValorEmDinheiro(e?.ValorEmprestimo)}</td>
          <td>{formatarValorEmDinheiro(e?.ValorLiberado)}</td>
          <td>{moment(e?.DataAverbacao).format('DD/MM/YYYY')}</td>
          <td>{moment(e?.InicioDesconto).format('DD/MM/YYYY')}</td>
          <td>{moment(e?.FinalDesconto).format('DD/MM/YYYY')}</td>
        </tr>
      </>
    );
  }


  return (
    <>
      <Breadcrumb
        name={`CONSULTA ${objNew?.Beneficiario?.Beneficio}`}
        button={[
          {
            name: "Imprimir Consulta",
            colorBtn: "primary",
            click: handlePrintToPDF,
            value: data,
            view: true,
          },
        ]}
      />
      {
        objNew &&
        objNew.length > 0 &&
        objNew.map((e) => {
          const obj = e?.Beneficiario;

          const contratos = e?.Emprestimos;

          const cartaoRmc = e?.Rmc;
          const cartaoRcc = e?.RCC;

          const DadosBancarios = e?.DadosBancarios;
          const ResumoFinanceiro = e?.ResumoFinanceiro;

          const DadosRepresentante = e?.DadosRepresentante

          const Associacao = e?.Associacao;

          const utilizada =
            contratos && contratos.length > 0
              ? contratos.reduce((a, b) => a + b.ValorParcela, 0)
              : 0;

          const margemExtrapolada =
            ResumoFinanceiro.BaseCalculo * 0.35 - utilizada > 0
              ? 0
              : ResumoFinanceiro.BaseCalculo * 0.35 - utilizada;

          return (
            <>
              <div>
                <section className="section contact">
                  <div className="row gy-4">
                    <div className="col-xl-12 ">
                      <div className="row">
                        <div style={{ textAlign: "center" }}>
                          <h4>Histórico de</h4>
                          <h3>
                            <b>Empréstimo Consignado</b>
                          </h3>
                          <p
                            style={
                              {
                                width: "100%",
                                padding: "1.5px",
                                backgroundColor: "#ccc",
                              }
                            }
                          ></p>
                          <p>{formatName(obj?.Nome) || "Não Informado"}</p>
                        </div>
                        <div className="info-box card">
                          {/* <i className="ri-home-5-fill"></i> */}
                          <h3>Benefício</h3>
                          <br />
                          <div className="row">
                            <div className="col-lg-6">
                              <p>
                                <b>Especie:</b>{" "}
                                {
                                  obj?.Especie || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>N° Benefício:</b>{" "}
                                {
                                  obj?.Beneficio || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>CPF:</b>{" "}
                                {
                                  obj?.CPF || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Data nascimento:</b>{" "}
                                {
                                  moment(obj?.DataNascimento).format('DD/MM/YYYY') || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Situação:</b>{" "}
                                {
                                  formatName(obj?.Situacao) || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Banco:</b>{" "}
                                {
                                  DadosBancarios?.Banco || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Agência:</b>{" "}
                                {
                                  DadosBancarios?.Agencia || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Conta Pagamento:</b>{" "}
                                {
                                  DadosBancarios?.ContaPagto || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Conta Corrente:</b>{" "}
                                {
                                  DadosBancarios?.MeioPagamento || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>DIB:</b>{" "}
                                {
                                   moment(obj?.DIB).format('DD/MM/YYYY') || "Não Informado"          
                                }
                              </p>
                              <p>
                                <b>DDB:</b>{" "}
                                {
                                  moment(obj?.DDB).format('DD/MM/YYYY') || "Não Informado"
                                }
                              </p>
                            </div>
                            <div className="col-lg-6">
                              <p>
                                <b>Representante Legal:</b>{" "}
                                {
                                  DadosRepresentante?.Nome  || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Representante CPF:</b>{" "}
                                {
                                  DadosRepresentante?.CPF || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Associação:</b>{" "}
                                {
                                  Associacao?.TaxaAssociativa || "Não Informado"
                                }
                              </p>
                              <p>
                                <b>Taxa da Associação:</b>{" "}
                                {
                                  Associacao?.Parcela ? formatarValorEmDinheiro(Associacao?.Parcela) : "Não Informado"
                                }
                              </p>
                              <p>
                                {
                                  obj.BloqueadoEmprestimo === "1"
                                    ? "Bloqueado para empréstimo"
                                    : "Não Bloqueado para empréstimo"
                                }
                              </p>
                              <p>
                                <b>
                                  {
                                    obj.PermiteEmprestimo === "1"
                                      ? "Permitido para empréstimo"
                                      : "Não permitido para empréstimo"
                                  }
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="info-box card">
                          {/* <i className="ri-home-5-fill"></i> */}
                          <h3>
                            Margem para Empréstimo/Cartão e Resumo Financeiro
                          </h3>
                          <br />
                          <div className="row">
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td style={{ background: "#ccc" }}>
                                    Empréstimos
                                  </td>
                                  <td style={{ background: "#ccc" }}>RMC</td>
                                  <td style={{ background: "#ccc" }}>RCC</td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Base de Cálculo
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.BaseCalculo)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRmc)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRcc)
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Margem Consignável
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.BaseCalculo * 0.30)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRmc)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRcc)
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Margem Utilizada
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(utilizada)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(!Array.isArray(cartaoRmc) ? cartaoRmc.ValorParcela : 0)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(!Array.isArray(cartaoRcc) ? cartaoRcc.ValorParcela : 0)
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Margem Reservada
                                  </td>
                                  <td>R$ 0,00</td>
                                  <td>-</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Margem Disponível
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelEmprestimo)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRmc)
                                    }
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(ResumoFinanceiro.MargemDisponivelRcc)
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ background: "#ccc" }}>
                                    Margem Extrapolada
                                  </td>
                                  <td>
                                    {
                                      formatarValorEmDinheiro(margemExtrapolada)
                                    }
                                  </td>
                                  <td>R$ 0,00</td>
                                  <td>R$ 0,00</td>
                                </tr>
                              </tbody>
                            </table>
                            <div>
                              <p>
                                * A margem consignável atual representa 45% da
                                base de cálculo para empréstimos. Dessa margem,
                                35% é para empréstimos e 10% para cartão, sendo
                                5% para RMC e 5% para RCC. ** O valor da margem
                                reservada está incluído no valor da margem
                                utilizada. *** A margem extrapolada representa o
                                valor que excedeu a margem disponível, que pode
                                ocorrer em situações específicas como a redução
                                da renda do benefício.
                              </p>
                            </div>
                          </div>
                        </div>

                        <div
                          className="info-box card"
                          style={{ pageBreakInside: "avoid" }}
                        >
                          <div
                            className="d-none"
                            id="dividerPrint"
                            style={{ height: "3rem" }}
                          >
                            {" "}
                          </div>

                          {/* <i className="ri-home-5-fill"></i> */}
                          <h3>Empréstimos Bancários</h3>
                          <br />
                          <div className="row">
                            <table className="table table-bordered">
                              <tbody>
                                <tr>
                                  <td colSpan="9">
                                    Contratos Ativos e Suspensos
                                  </td>
                                </tr>
                                <tr>
                                  <td>Banco</td>
                                  <td>Valor Parcela</td>
                                  <td>Quitacao</td>
                                  <td>Prazo</td>
                                  <td>Tipo</td>
                                  <td>Taxa</td>
                                  <td>Parcelas Restantes</td>
                                  <td>Contrato</td>
                                  <td>Valor Emprestimo</td>
                                  <td>Valor Liberado</td>
                                  <td>Data Averbação</td>
                                  <td>Início Desconto</td>
                                  <td>Final Desconto</td>
                                </tr>

                                {contratos && contratos.length > 0 ? (
                                  contratos.map((e) =>
                                    infoEmprestimos(e)
                                  )
                                ) : (
                                  <>
                                    <tr style={{ textAlign: "center" }}>
                                      <td colSpan="7">Não existem dados</td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="info-box card">
                          {/* <i className="ri-home-5-fill"></i> */}
                          <h3>Cartão de Crédito</h3>
                          <br />
                          <div className="row">
                            <table className="table table-bordered">
                              <tbody>
                                <tr style={{ textAlign: "center" }}>
                                  <td colSpan="7">
                                    <b>Cartão de Crédito - RMC</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="7">
                                    Contratos Ativos e Suspensos
                                  </td>
                                </tr>
                                <tr>
                                  <td>Banco</td>
                                  <td>Valor</td>
                                  <td>Valor Parcela</td>
                                  <td>Contrato</td>
                                  <td>Valor Emprestimo</td>
                                  <td>Data Inclusão</td>
                                </tr>
                                {
                                  cartaoRmc?.Banco && !Array.isArray(cartaoRmc) ? (
                                    infoRmc(cartaoRmc)
                                  ) : (
                                    <tr style={{ textAlign: "center" }}>
                                      <td colSpan="7">Não existem dados</td>
                                    </tr>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                          <div className="row">
                            <table className="table table-bordered">
                              <tbody>
                                <tr style={{ textAlign: "center" }}>
                                  <td colSpan="7">
                                    <b>Cartão de Crédito - RCC</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan="7">
                                    Contratos Ativos e Suspensos
                                  </td>
                                </tr>
                                <tr>
                                  <td>Banco</td>
                                  <td>Valor</td>
                                  <td>Valor Parcela</td>
                                  <td>Contrato</td>
                                  <td>Valor Emprestimo</td>
                                  <td>Data inclusão</td>
                                </tr>
                                {
                                  !Array.isArray(cartaoRcc) && cartaoRcc?.Banco ? (
                                    infoRcc(cartaoRcc)
                                  ) : (
                                    <>
                                      <tr style={{ textAlign: "center" }}>
                                        <td colSpan="7">Não existem dados</td>
                                      </tr>
                                    </>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          );
        })
      }
    </>
  );
};

export default OfflineCpf;
